@tailwind base;
@tailwind components;
@tailwind utilities;

html,body {
  height: 100%;
  width: 100%;
}

#app {
  height: 100%;
}

body {
  background-image: url('./images/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

#code, #progress-text {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
